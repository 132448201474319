body{
background-color:#193996;
    }

.name-input {
text-align:center;
    }

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App-header {
  background-color: #263060;
  overflow: visible;
  width: 50%;
  text-align: center;
  color: white;
  border-radius: 2rem;
  margin: 0 auto;
  margin-top:1rem;
}

.Messages-list {
  padding: 20px 0;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
  flex-grow: 1;
  overflow: auto;
}

.Messages-message {
  display: flex;
  margin-top: 10px;
}

.Messages-message.currentMember {
  /*justify-content: flex-end;*/
  flex-direction: row-reverse;
  text-align: right;
}

.Message-content {
  display: inline-block;
}

.currentMember > .Message-content {
  align-items: flex-end;
}

.Messages-message > .avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: none;
  margin: 0 10px -10px;
}

.Message-content > .username {
  display: block;
  //color: gray;
  font-size: 24px;
  padding-bottom: 4px;
}

.Message-content > .text {
  padding: 10px;
  max-width: 400px;
  margin: 0;
  border-radius: 12px;
  background-color: cornflowerblue;
  color: white;
  display: inline-block;
}

.currentMember > .Message-content .text {
  background-color: orangered;
}

form {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto 40px;
}

input {
  padding: 5px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid orangered;
  flex-grow: 1;
}

button {
  padding: 5px 10px;
  font-size: 16px;
  background-color: orangered;
  color: white;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
}
